import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from "../components/layout"
import Post from '../components/blogPost'
import Seo from "../components/seo"
import { graphql } from "gatsby"
import React from 'react'

const BlogPost = ({ data }) => {
    const { teamNodes: { nodes: teamMembers } } = data
    const moreTeam = teamMembers.map(({ name, teamMemberLandscapeImage, companyPosition }) => {
        return (
            <Post title={name} key={name} companyPosition={companyPosition} image={teamMemberLandscapeImage} team={true} />
        )
    })
    return (
        <Layout>
            <Seo title={data.team.metaTitle} description={data.team.metaDescription} />
            <div className="spacer half"></div>
            <div className="title-area p20 flex max-1000 ma m-wrap">
                <div className="w-50 m-100">
                <h1 className="m0 mb20">{data.team.name}</h1>
                <p className="m0 text-purple uppercase">{data.team.companyPosition}</p>  
                {( data.team.awards ?
                    <div className='flex mt20 gap-20'> 
                        {data.team.awards.map((award, index) => { 
                          return (
                            <div className='award-icon small'>
                                <GatsbyImage image={award.gatsbyImageData} alt='icon' className="bg-image" /> 
                            </div> 
                          )
                        })}
                    </div>:''
                )}
                </div>
                <div className="w-50 m-100 m-mt40">
                    <div className="max-500">
                        <h3 className="m0 ">{data.team.about}</h3>
                    </div>
                </div>
            </div>
            <div className="spacer half"></div>
            <div className="feature--image pos-rel">
                <div className="max-1000 ma pos-rel">
                    <div className="ratio-1-2">
                        <GatsbyImage image={data.team.teamMemberLandscapeImage.gatsbyImageData} objectPosition="center top" alt={data.team.name} className="bg-image" />
                    </div>
                </div>
                <div className="background--half" style={{ backgroundColor: '#ECECEC' }}></div>
            </div>
            <div className="p20" style={{ backgroundColor: '#ECECEC' }}>
                {data.team.byline ?<p className="m0 text-purple max-600 mt40 ma prewrap mb20 sainte ">{data.team.byline}</p> : null}
                <div className={("max-600  mb40 ma prewrap sainte " + (data.team.byline ? "" : "mt40"))} dangerouslySetInnerHTML={{ __html: data.team.content }}>
                </div>
            </div>
            <div className="related--posts">
                <div className="spacer half"></div>
                <div className="title-area p20">
                    <p className="h2 m0">Other Team Members</p>
                </div>
                <div className="posts--grid grid gap-v-60 m-p20">
                    {moreTeam}
                </div>
                <div className="spacer half m-hide"></div>
            </div>
        </Layout>
    )
}
export default BlogPost


export const pageQuery = graphql`
    query team($name: String!){
        team:datoCmsTeamMember(name: {eq: $name}){
            about
            name
            metaTitle
            companyPosition
            content
            byline
            metaDescription
            awards {
                gatsbyImageData
            }
            teamMemberLandscapeImage {
                gatsbyImageData
            }
        } 
        teamNodes: allDatoCmsTeamMember(filter: {name: {ne: $name}}, limit: 3)  {
            nodes {
                about
                name
                companyPosition
                content
                byline
                teamMemberLandscapeImage {
                    gatsbyImageData
                }
            }
        }
    }
`
